
        import * as module0 from './controllers/fonts_controller.js';import * as module1 from './controllers/pressure-chart_controller.js';import * as module2 from './controllers/purchasable-service_controller.js';import * as module3 from './controllers/purchasable-services_controller.js';import * as module4 from './controllers/toggle_controller.js'
        const modules = {
            "./controllers/fonts_controller.js": module0,
            "./controllers/pressure-chart_controller.js": module1,
            "./controllers/purchasable-service_controller.js": module2,
            "./controllers/purchasable-services_controller.js": module3,
            "./controllers/toggle_controller.js": module4,
        };
        export default modules;
      